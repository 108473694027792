import React, { useState, useEffect } from "react";
import { Styling } from "./styled.js";
import { Accordion } from "react-accessible-accordion";
import CurriculumContentButtons from "./CurriculumContentButtons";
import CurriculumChapter from "./CurriculumChapter";
import DownloadableContent from "./DownloadableContent";
import gsap from "gsap";

export default function Curriculum({ curriculum, downloadCount, setCurrentLesson, downloadableContent, currentLesson, setCurrentChapterIndex, setCurrentLessonIndex, courseProgress, enrollmentStatus }) {
  const [activeButton, setActiveButton] = useState("content");
  const [completed, setCompleted] = useState();
  let completedArr = [];
  const handleClick = (e) => {
    const target = e.target.closest("button");
    setActiveButton(target.id);

    if (target.id === "content") {
      gsap.to(".downloadableContent", { x: "100%" });
      gsap.to(".accordion", { x: "0%" });
    } else {
      gsap.to(".downloadableContent", { x: "0%" });
      gsap.to(".accordion", { x: "-100%" });
    }
  };

  const setCompletedFunction = () => {
    courseProgress?.filter((course) => {
      course.lessons.filter((lesson) => {
        if (lesson.lessonComplete === true) {
          completedArr.push(lesson.lessonTitle);
        }
      });
    });
    setCompleted(completedArr);
  };

  useEffect(() => {
    setCompletedFunction();
  }, [courseProgress]);

  return (
    <Styling className="curriculum">
      {enrollmentStatus === true ? (
        <>
          <CurriculumContentButtons activeButton={activeButton} handleClick={handleClick} downloadCount={downloadCount} />
          <Accordion allowZeroExpanded>
            {curriculum.chapter.map((chapter, index) => {
              return <CurriculumChapter chapter={chapter} index={index} courseProgress={courseProgress} completed={completed} setCurrentChapterIndex={setCurrentChapterIndex} setCurrentLessonIndex={setCurrentLessonIndex} currentLesson={currentLesson} setCurrentLesson={setCurrentLesson} />;
            })}
          </Accordion>
          {downloadableContent !== 0 ? <DownloadableContent downloadableContent={downloadableContent} /> : ""}
        </>
      ) : (
        ""
      )}
    </Styling>
  );
}
