import React from "react";
import Download from "../../../../images/download.inline.svg";

export default function DownloadableContent({ downloadableContent }) {
  const handleClick = (e) => {
    e.preventDefault();
    window.open(e.target.dataset.fileUrl, "_blank");
  };

  return (
    <div className="downloadableContent">
      {downloadableContent?.map((lesson, index) => {
        console.log(lesson);
        return (
          <div onClick={(e) => handleClick(e)} data-file-url={lesson.lessonFileUrl} download className={`downloadableContentItem downloadableContentItem-${index}`}>
            <div className="lesson_title">{lesson.fileTitle}</div>
            <div className="download">
              <Download className="downloadIcon" /> Download
            </div>
          </div>
        );
      })}
    </div>
  );
}
